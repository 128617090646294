
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { InputSearch } from '@/components'
import store from '@/store'
export default defineComponent({
  name : 'Logs',
  components:{
    InputSearch
  },
  computed: {
    ...mapGetters([
      'corporateLogs'
    ])
  },
  data(){
    return{
      currentPage: 1,
      currentPageSize: 10,
      searchText: '',
      searchBarDelay: 0,
      dateFrom: '',
      dateTo: '',
      showSpinner: false,
      enableToSearch: true,
      lastTotalLogs: 0
    }
  },
  methods:{
    searchLogsByText(){
      clearTimeout(this.searchBarDelay)
      this.searchBarDelay = setTimeout(
        ( ) => this.searchLogsByFilters(), 500
      );
    },
    searchLogsByFilters(){
      this.currentPageSize = 10;
      this.getCorporateLogs();
    },
    async getCorporateLogs(){
      const filters = {
        dateFrom: this.dateFrom, 
        dateTo: this.dateTo, 
        text: this.searchText
      }
      await store.dispatch('fetchCorporateLogs', {
        page: this.currentPage - 1,
        pageSize: this.currentPageSize,
        data: filters
      });
      this.showSpinner = false;
      if (this.lastTotalLogs != this.corporateLogs.length) {
        this.enableToSearch = true;
        this.lastTotalLogs = this.corporateLogs.length;
      } else {
        this.enableToSearch = false;
      }
    },
    changeLogsPage(){
      this.getCorporateLogs();
    },
    handleScroll() {
      if (this.enableToSearch) {
        this.showSpinner = true;
        const scrollTop = document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        if (scrollTop + windowHeight >= documentHeight) {
          this.currentPageSize += 10;
          this.getCorporateLogs();
        }
      }
    }
  },
  mounted(){
    this.getCorporateLogs();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
})
